<template>
  <div class="price-chart">
    <f7-segmented class="price-range-buttons" raised tag="p">
      <f7-button color="gray" 
                 v-for="({text, type, count},index) in rangeButtons" :key="text"
                 :active="index === clickedIndex"
                 @click="loadRangeData(type, count, index)">{{ text }}</f7-button>
    </f7-segmented>
    <div class="chart-canvas" ref="priceChartContainer" style="height: 280px"/>
  </div>
</template>


<script>
import Highcharts from 'highcharts'
import 'highcharts/modules/boost'
import marketValueApi from '../apis/companyMarketData'

const rangeButtons = [
  { type: 'day', count: 5, text: '5D' },
  { type: 'month', count: 1, text: '1M' },
  { type: 'month', count: 6, text: '6M' },
  { type: 'day', count: getPastDays(), text: 'YTD' },
  { type: 'year', count: 1, text: '1Y' },
  { type: 'year', count: 2, text: '2Y' },
  { type: 'year', count: 5, text: '5Y' },
  { type: 'year', count: 20, text: 'MAX' }
]

function getPastDays(start = new Date()) {
  return Math.floor((start - new Date(start.getFullYear(), 0, 1)) / 24 / 36e5)
}

function getDateString(date = new Date()) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}${String(month).padStart(2, 0)}${String(day).padStart(2, 0)}`
}

function getStartDate(type, count) {
  const today = new Date()
  const typeMap = { day: 'Date', month: 'Month', year: 'FullYear' }
  const unit = typeMap[type]
  const startDate = new Date(today[`set${unit}`](today[`get${unit}`]() - count))
  return startDate
}

function initDraw(container, seriesData) {
  const chartOptions = {
    chart: {
      style: {
        fontFamily: 'Roboto Condensed, sans'
      }
    },
    title: { text: '' },
    plotOptions: {
      line: { marker: { enabled: false } },
      series: { enableMouseTracking: false, boostThreshold: 500 }
    },
    credits: { enabled: false },
    tooltip: { enabled: false },
    xAxis: { type: 'datetime' },
    yAxis: { title: null, align: 'right' },
    series: [{ type: 'line', data: seriesData, showInLegend: false }]
  }

  if (container && chartOptions) {
    return Highcharts.chart(container, chartOptions)
  }
}

export default {
  props: {
    issuerId: { type: [String, Number], required: true }
  },
  chart: null, //Don't add reactivity
  data() {
    return {
      clickedIndex: 4,
      rangeButtons: Object.freeze(rangeButtons)
    }
  },
  async mounted() {
    const { type, count } = rangeButtons[this.clickedIndex]
    const seriesData = await this.fetchChartData(type, count)
    const container = this.$refs.priceChartContainer
    this.$options.chart = initDraw(container, seriesData)
  },
  methods: {
    async loadRangeData(type, count, index) {
      this.clickedIndex = index
      let rangeSeriesData = await this.fetchChartData(type, count)
      //use weekly data for 5Y and Max
      if (rangeSeriesData.length > 1000) {
        rangeSeriesData = rangeSeriesData.filter((data, index) => index % 7 === 0)
      }
      this.$options.chart.series[0].setData(rangeSeriesData)
    },
    async fetchChartData(type, count) {
      const startDate = getDateString(getStartDate(type, count))
      return await marketValueApi.getPriceChartData(this.issuerId, startDate)
    }
  }
}
</script>

<style>
.price-chart .price-range-buttons {
  margin: 4px 12px;
}
.price-chart .chart-canvas {
  padding: 0 4px;
  margin-top: 50px;
}
</style>
